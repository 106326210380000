<template>
  <div>
    <show-credential-terminales-tef ref="ShowCredentialTerminalesTef" />
  </div>
</template>
<script>
  export default {
    components: {
      ShowCredentialTerminalesTef: () => import('./components/ShowCredentialTerminalesTef'),
    },
  }
</script>
